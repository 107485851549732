import jwt from 'jsonwebtoken';

import router from './../router';
import { getIdEmpresaDefault } from './../services/multiple-empresa';

export function logout() {
  try {
    const userToken = localStorage.getItem('user-token');

    localStorage.removeItem('rotaEntrada');
    localStorage.removeItem('user-token');
    localStorage.removeItem('listEmpresas');
    localStorage.removeItem('idUsuarioInitialLogin');

    let idEmpresa = getIdEmpresaDefault();

    if (!idEmpresa) {
      const decoded = jwt.decode(userToken);
      idEmpresa = decoded.id_empresa;
    }

    router.push(`/?idEmpresa=${idEmpresa}`);
  } catch (error) {
    router.push('/');
  }
}
